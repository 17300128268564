import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getDollarSettingData, updateDollarSettingData  } from '../../actions/settingAction';

import { Button } from 'reactstrap';

import AuthorizedMenu from './AuthorizedMenu';
import UnAuthorizedMenu from './UnAuthorizedMenu';
import { convertToRupiah } from '../../utils';

import { ModalDollarRate } from '../Modals';
import { saleMenus, saleMenusDelivering, consignmentMenus } from '../../utils/authorization';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // availableRoutes: []
      isOpen: false
    }
    this.handleIsActive = this.handleIsActive.bind(this);
  }

  componentDidMount(){
    this.props.getDollarSettingData();
  }

  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle('open');
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
  }

  activeRoutes(routeNames = []) {
    routeNames.map(name => {
      if (this.props.location.pathname.indexOf(name) > -1) {
        return 'nav-item nav-dropdown open'
      }
    })

    return 'nav-item nav-dropdown'
  }

  handleIsActive(match){
    return match !== null && match.isExact;
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  renderAuthorisedSaleMenusDelivering = (authorisedSaleMenusDelivering) => {
    if (authorisedSaleMenusDelivering.length === 0) return null;
    
    // Define the desired order
    const order = [
      'sales/jnt-delivering',
      'sales/tiki-delivering',
      'sales/instant-delivering',
      'sales/warehouse-delivering',
      'sales/other-delivering'
    ];
    
    // Sort the menu items according to the order
    const sortedMenus = order.filter(item => authorisedSaleMenusDelivering.includes(item));
    
    return sortedMenus.map((v, i) => (
      <li className="nav-item" key={i}>
        <NavLink to={`/${v}`} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>{saleMenusDelivering[v]}</NavLink>
      </li>
    ));
  }

  // secondLevelActive(routeName) {
  //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
  // }

  render() {
    const { authData } = this.props;
    return (
      <div className="sidebar">
        <ModalDollarRate
          isOpen={this.state.isOpen}
          toggle={this.handleToggle}
          data={this.props.dataDollarSetting}
          onSubmit={payloads => this.props.updateDollarSettingData(payloads)}
        />
        <nav className="sidebar-nav">
          <ul className="nav">
            <li className="nav-item">
              <NavLink to={'/dashboard'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="icon-speedometer"></i> Dashboard <span className="badge badge-info d-none">NEW</span></NavLink>
            </li>

            {/* Analitycs */}
            <AuthorizedMenu
              isLoading={authData.isFetch}
              routes={authData.authRoutes}
              routeName={['survey', 'faqs']}
              render={() => (
                <li className={this.activeRoutes([])}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-pie-chart"></i> Analytics</a>
                    <ul className="nav-dropdown-items">
                      {/* Survey */}
                      <AuthorizedMenu
                        isLoading={authData.isFetch}
                        routes={authData.authRoutes}
                        routeName="survey" 
                        render={()=>(
                          <li className={this.activeRoute("/survey")}>
                            <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-list-ol"></i> Survey</a>
                            <ul className="nav-dropdown-items">
                              <li className="nav-item">
                                <NavLink to={'/survey/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Survey</NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink to={'/survey'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Survey List</NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink to={'/survey_option/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Survey Option</NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink to={'/survey_option'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Survey Option List</NavLink>
                              </li>
                            </ul>
                          </li>
                        )}
                      />

                      {/* FAQ */}
                      <AuthorizedMenu
                        isLoading={authData.isFetch}
                        routes={authData.authRoutes}
                        routeName="faqs"
                        render={()=>(
                          <li className={this.activeRoute("/faqs")}>
                            <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-question-circle"></i> FAQ</a>
                            <ul className="nav-dropdown-items">
                              <li className="nav-item">
                                <NavLink to={'/faqs/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create FAQ</NavLink>
                              </li>
                              <li className="nav-item">
                                <NavLink to={'/faqs'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> FAQ List</NavLink>
                              </li>
                            </ul>
                          </li>
                        )}
                      />
                    </ul>
                </li>
              )}
            />

            {/* Sales */}
            <AuthorizedMenu
              isLoading={authData.isFetch}
              routes={authData.authRoutes}
              routeName={['users', 'categories', 'menus', 'brands', 'collections', 'products', 'product_addons', 'product_variants', 'stock', 'concierge', 'offers', 'vouchers', 'raffles', 'rewards', 'point_shop', 'spinning_wheel', 'timer', 'push_notification', 'articles', 'metas', '3rdparty_stock']}
              render={() => (
                <li className={this.activeRoutes([])}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-line-chart"></i> Sales</a>
                  <ul className="nav-dropdown-items">
                    {/* users */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="users"
                      render={()=>(
                        <li className={this.activeRoute("/users")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-user"></i> Users</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/users/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create User</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/users'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>User List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* categories */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="categories"
                      render={()=>(
                        <li className={this.activeRoute("/categories")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-folder"></i> Categories</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/categories/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Categories</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/categories'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Categories List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Menus */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="menus"
                      render={()=>(
                        <li className={this.activeRoute("/menus")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-bars"></i> Menus</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/menus/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Menu</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/menus'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Menu List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* brands */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="brands"
                      render={()=>(
                        <li className={this.activeRoute("/brands")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-folder"></i> Brands</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/brands/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Brand</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/brands'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Brand List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* collections */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="collections"
                      render={()=>(
                        <li className={this.activeRoute("/collections")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-folder"></i> Collections</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/collections/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Collection</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/collections'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Collection List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* products */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="products"
                      render={()=>(
                        <li className={this.activeRoute("/products")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-folder"></i> Products</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/products/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Product</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/products'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Product List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* product addons */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="product_addons"
                      render={()=>(
                        <li className={this.activeRoute("/product_addons")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-folder"></i> Product Addons</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/product_addons/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Product Addon</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/product_addons'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Product Addon List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* product_variants */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="product_variants"
                      render={()=>(
                        <li className={this.activeRoute("/product_variants")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-folder"></i> Product Variants</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/product_variants/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Product Variant</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/product_variants'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Product Variant List</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/product_variants/sku_requests'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>SKU Requests</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* stock */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="stock"
                      render={()=>(
                        <li className="nav-item">
                          <NavLink to={'/stock'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="icon-drawer"></i> Stock</NavLink>
                        </li>
                      )}
                    />

                    {/* concierge */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="concierge"
                      render={()=>(
                        <li className={this.activeRoute("/concierge")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-credit-card"></i> Concierge</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/concierge/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Concierge</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/concierge'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>All Concierge List</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/concierge/pending'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Pending Concierge</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/concierge/processed'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Processed Concierge</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/concierge/completed'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Completed Concierge</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/concierge/Failed'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Failed Concierge</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Offer */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="offers"
                      render={()=>(
                        <li className="nav-item">
                          <NavLink to={'/offers'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="icon-envelope-letter"></i> Offers / Bids</NavLink>
                        </li>
                      )}
                    />

                    {/* vouchers */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="vouchers"
                      render={()=>(
                        <li className={this.activeRoute("/vouchers")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-ticket"></i> Vouchers</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/vouchers/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Voucher</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/vouchers'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Voucher List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* raffles */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="raffles"
                      render={()=>(
                        <li className={this.activeRoute("/raffles")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-ticket" style={{transform: 'rotate(45deg)'}}></i> Raffles</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/raffle_group/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Raffle Group</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/raffle_group'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Raffle Group List</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/raffles/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Raffle</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/raffles'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Raffle List</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/raffle_requests'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Raffle Requests</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/raffle_tickets'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Raffle Tickets</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Buyer Rewards */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="rewards"
                      render={()=>(
                        <li className="nav-item">
                          <NavLink to={'/rewards'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="fa fa-trophy"></i> Buyer Rewards</NavLink>
                        </li>
                        // <li className={this.activeRoute("/rewards")}>
                        //   <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-trophy"></i> Buyer Rewards</a>
                        //   <ul className="nav-dropdown-items">
                        //     {/* <li className="nav-item">
                        //       <NavLink to={'/rewards/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Reward</NavLink>
                        //     </li> */}
                        //     <li className="nav-item">
                        //       <NavLink to={'/rewards'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Reward List</NavLink>
                        //     </li>
                        //   </ul>
                        // </li>
                      )}
                    />

                    {/* point shop */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="point_shop"
                      render={()=>(
                        <li className={this.activeRoute("/point_shop")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-shopping-cart"></i> Point Shop</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/point_shop/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Point Shop</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/point_shop'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Point Shop List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Spinning Wheel */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="spinning_wheel"
                      render={()=>(
                        <li className={this.activeRoute("/spinning_wheel")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-circle-o"></i> Spinning Wheel</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/spinning_wheel/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Spinning Wheel</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/spinning_wheel'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Spinning Wheel List</NavLink>
                            </li>
                            
                          </ul>
                        </li>
                      )}
                    />

                    {/* Running Text */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="timer" 
                      render={()=>(
                        <li className={this.activeRoute("/timer")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-clock-o"></i> Running Text</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/timer/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Running Text</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/timer'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Running Text List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Push Notification */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="push_notification" 
                      render={()=>(
                        <li className={this.activeRoute("/push_notification")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-bell"></i> Push Notification</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/push_notification/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Push Notification</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/push_notification'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Push Notification List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Article */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="articles"
                      render={()=>(
                        <li className={this.activeRoute("/articles")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-newspaper-o"></i> Article</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/articles/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Article</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/articles'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Article List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Meta */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="metas"
                      render={()=>(
                        <li className={this.activeRoute("/metas")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-newspaper-o"></i> Meta Data</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/metas/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Meta Data</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/metas'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Meta Data List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Third Party */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="3rdparty_stock"
                      render={()=>(
                        <li className="nav-item">
                          <NavLink to={'/3rdparty_stock'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="icon-drawer"></i> 3rd Party Stock</NavLink>
                        </li>
                      )}
                    />

                    {/* Bank Promo */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="bank_promo"
                      render={()=>(
                        <li className={this.activeRoute("/bank_promo")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-university"></i> Bank Promo</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/bank_promo/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Bank Promo</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/bank_promo'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Bank Promo List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                  </ul>
                </li>
              )}
            />
            

            {/* Operations */}
            <AuthorizedMenu
              isLoading={authData.isFetch}
              routes={authData.authRoutes}
              routeName={['country', 'sell_request', 'racks', 'warehouse', 'sales', 'consignment']}
              render={() => (
                <li className={this.activeRoutes([])}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-archive"></i> Operations</a>
                  <ul className="nav-dropdown-items">
                    {/* Country */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="country"
                      render={()=>(
                        <li className={this.activeRoute("/country")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-globe"></i> Country</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/country'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Country List</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/country/update-batch'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Update Country</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* sell_request */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="sell_request"
                      render={()=>(
                        <li className="nav-item">
                          <NavLink to={'/sell_request'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="fa fa-map-signs"></i> Sell & Consignment Requests</NavLink>
                        </li>
                      )}
                    />

                    {/* Racks */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="racks"
                      render={()=>(
                        <li className={this.activeRoute("/racks")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-bars"></i> Racks</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/racks/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Rack</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/racks'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Rack List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Warehouse */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="warehouse"
                      render={()=>(
                        <li className="nav-item">
                          <NavLink to={'/warehouse'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="fa fa-database"></i> Warehouse</NavLink>
                        </li>
                      )}
                    />

                    {/* Sales */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="sales"
                      render={()=>{
                        const hasOne = authData.authRoutes.filter(i => i !== 'sales').length === 1;
                        const first = hasOne? authData.authRoutes.filter(i => i !== 'sales')[0]: null;
                        const authorisedSaleMenusDelivering = authData.authRoutes.filter(v => Object.keys(saleMenusDelivering).includes(v));
                        return(
                          <li className={this.activeRoute("/sales")}>
                            <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-line-chart"></i> Sales</a>
                            <ul className="nav-dropdown-items">
                              {Object.keys(saleMenus).filter(i => i!=='sales').map((i, key) => (
                                <AuthorizedMenu
                                  key={key}
                                  isLoading={authData.isFetch}
                                  routes={authData.authRoutes}
                                  routeName={i}
                                  render={()=> {
                                    if(i === 'sales/delivering'){
                                      if(first  === 'sales/delivering'){
                                        return (
                                          <>
                                            {this.renderAuthorisedSaleMenusDelivering(authorisedSaleMenusDelivering)}
                                          </>
                                        )
                                      }
                                      return (
                                        <li className="nav-item nav-dropdown">
                                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}>{saleMenus[i]}</a>
                                          <ul className="nav-dropdown-items">
                                            {this.renderAuthorisedSaleMenusDelivering(authorisedSaleMenusDelivering)}
                                          </ul>
                                        </li>
                                      )
                                    }
                                    return(
                                      <li className="nav-item">
                                        <NavLink to={`/${i}`} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>{saleMenus[i]}</NavLink>
                                      </li>
                                    )
                                  }}
                                />
                              ))}
                            </ul>
                          </li>
                        )
                      }}
                    />

                      {/* Consignment */}
                      <UnAuthorizedMenu
                          isLoading={authData.isFetch}
                          routes={authData.authRoutes}
                          routeName="consignment"
                          render={()=>{
                              const hasOne = authData.authRoutes.filter(i => i !== 'consignment').length === 1;
                              const first = hasOne? authData.authRoutes.filter(i => i !== 'consignment')[0]: null;
                              return(
                                  <li className={this.activeRoute("/consignment")}>
                                      <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-archive"></i> Consignment</a>
                                      <ul className="nav-dropdown-items">
                                          {Object.keys(consignmentMenus).filter(i => i!=='consignment').map((i, key) => (
                                              <UnAuthorizedMenu
                                                  key={key}
                                                  isLoading={authData.isFetch}
                                                  routes={authData.authRoutes}
                                                  routeName={i}
                                                  render={()=> {
                                                      if(i === 'consignment/delivering'){
                                                          if(first  === 'consignment/delivering'){
                                                              return (
                                                                  <>
                                                                      <li className="nav-item">
                                                                          <NavLink to={'/consignment/jnt-delivering'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>JNT</NavLink>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <NavLink to={'/consignment/instant-delivering'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Gosend/Grab</NavLink>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <NavLink to={'/consignment/warehouse-delivering'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Warehouse</NavLink>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <NavLink to={'/consignment/other-delivering'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Others</NavLink>
                                                                      </li>
                                                                  </>
                                                              )
                                                          }
                                                          return (
                                                              <li className="nav-item nav-dropdown">
                                                                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}>{consignmentMenus[i]}</a>
                                                                  <ul className="nav-dropdown-items">
                                                                      <li className="nav-item">
                                                                          <NavLink to={'/consignment/jnt-delivering'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>JNT</NavLink>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <NavLink to={'/consignment/instant-delivering'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Gosend/Grab</NavLink>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <NavLink to={'/consignment/warehouse-delivering'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Warehouse</NavLink>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <NavLink to={'/consignment/other-delivering'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Others</NavLink>
                                                                      </li>
                                                                  </ul>
                                                              </li>
                                                          )
                                                      }
                                                      return(
                                                          <li className="nav-item">
                                                              <NavLink to={`/${i}`} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>{consignmentMenus[i]}</NavLink>
                                                          </li>
                                                      )
                                                  }}
                                              />
                                          ))}
                                      </ul>
                                  </li>
                              )
                          }}
                      />

                  </ul>
                </li>
              )}
            />
            

            {/* Creative */}
            <AuthorizedMenu
              isLoading={authData.isFetch}
              routes={authData.authRoutes}
              routeName={['sliders', 'videos', 'pop_up_banner']}
              render={() => (
                <li className={this.activeRoutes([])}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-picture-o"></i> Creative</a>
                  <ul className="nav-dropdown-items">
                    {/* sliders */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="sliders"
                      render={()=>(
                        <li className={this.activeRoute("/sliders")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-picture"></i> Sliders</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/sliders/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Slider</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/sliders'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Slider List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* videos */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="videos"
                      render={()=>(
                        <li className={this.activeRoute("/videos")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-video-camera"></i> Videos</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/videos/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Video</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/videos'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Video List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Pop Up Banner */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="pop_up_banner"
                      render={()=>(
                        <li className={this.activeRoute("/pop_up_banner")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-image"></i> Pop Up Banner</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/pop_up_banner/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Pop Up Banner</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/pop_up_banner'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Pop Up Banner List</NavLink>
                            </li>            
                          </ul>
                        </li>
                      )}
                    />
                  </ul>
                </li>
              )}
            />
            

            {/* Finance */}
            <AuthorizedMenu
              isLoading={authData.isFetch}
              routes={authData.authRoutes}
              routeName={['bank_accounts', 'seller_verification', 'payments', 'top_up', 'disbursements', 'marketing_budgets', 'midtrans']}
              render={() => (
                <li className={this.activeRoutes([])}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-money"></i> Finance</a>
                  <ul className="nav-dropdown-items">
                    {/* bank_accounts */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="bank_accounts"
                      render={()=>(
                        <li className={this.activeRoute("/bank_accounts")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-folder"></i> Bank Account</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/bank_accounts/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Bank Account</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/bank_accounts'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Bank Account List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* Seller Verification */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="seller_verification"
                      render={()=>(
                        <li className="nav-item">
                          <NavLink to={'/seller_verification'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="fa fa-check-square-o"></i> Seller Verification</NavLink>
                        </li>
                      )}
                    />

                    {/* payments */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="payments"
                      render={()=>(
                        <li className={this.activeRoute("/payments")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-exchange"></i> Payments</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/payments/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Payment</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/payments'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Payment List</NavLink>
                            </li>
                            <AuthorizedMenu
                              isLoading={authData.isFetch}
                              routes={authData.authRoutes}
                              routeName="payments/refund-to-balance"
                              render={()=>(
                                <li className="nav-item">
                                  <NavLink to={'/payments/refund-to-balance'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Refund to Balance</NavLink>
                                </li>
                              )}
                            />
                          </ul>
                        </li>
                      )}
                    />

                    {/* top_up */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="top_up"
                      render={()=>(
                        <li className="nav-item">
                          <NavLink to={'/top_up'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="icon-wallet"></i>Top-up Payments</NavLink>
                        </li>
                        // <li className={this.activeRoute("/top_up")}>
                        //   <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-wallet"></i>Top-up Payments</a>
                        //   <ul className="nav-dropdown-items">
                        //     <li className="nav-item">
                        //       <NavLink to={'/top_up'} className="nav-link" activeClassName="active">Top-up Payments List</NavLink>
                        //     </li>
                        //   </ul>
                        // </li>
                      )}
                    />

                    {/* disbursements */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="disbursements"
                      render={()=>(
                        <li className={this.activeRoute("/disbursements")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-money"></i> Disbursements</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/disbursements/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Disbursement</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/disbursements'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>All Disbursement</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/disbursements/pending'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Pending Disbursements</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/disbursements/completed'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Completed Disbursements</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* marketing budgets */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="marketing_budgets"
                      render={()=>(
                        <li className={this.activeRoute("/marketing_budgets")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-list-alt"></i> Marketing Budgets</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/marketing_budgets/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Marketing Budget</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/marketing_budgets'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Marketing Budget List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                    {/* midtrans */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="midtrans"
                      render={()=>(
                        <li className="nav-item">
                          <NavLink to={'/midtrans'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}><i className="fa fa-exchange"></i> Midtrans</NavLink>
                        </li>
                      )}
                    />

                  </ul>
                </li>
              )}
            />

            {/* Admin Console */}
            <AuthorizedMenu
              isLoading={authData.isFetch}
              routes={authData.authRoutes}
              routeName={['roles']}
              render={() => (
                <li className={this.activeRoutes([])}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-user-md"></i> Admin Console</a>
                  <ul className="nav-dropdown-items">

                    {/* roles */}
                    <AuthorizedMenu
                      isLoading={authData.isFetch}
                      routes={authData.authRoutes}
                      routeName="roles"
                      render={()=>(
                        <li className={this.activeRoute("/roles")}>
                          <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="icon-people"></i> Roles</a>
                          <ul className="nav-dropdown-items">
                            <li className="nav-item">
                              <NavLink to={'/roles/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create Role</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink to={'/roles'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Role List</NavLink>
                            </li>
                          </ul>
                        </li>
                      )}
                    />

                  </ul>
                </li>
              )}
            />

            <AuthorizedMenu
              isLoading={authData.isFetch}
              routes={authData.authRoutes}
              routeName="app_sections"
              render={()=>(
                <>
                  <li className="nav-title">
                    <i className="fa fa-cog mr-1"></i> App Setting
                  </li>
                  <li className={this.activeRoute("/app_sections")}>
                    <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-star-o"></i> App Sections</a>
                    <ul className="nav-dropdown-items">
                      <li className="nav-item">
                        <NavLink to={'/app_sections/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>Create App Section</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to={'/app_sections'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}>App Section List</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Button block className="nav-link text-left" style={{border: 'none', padding: '6px 1rem'}} onClick={this.handleToggle}>
                      <i className="fa fa-dollar"></i> Dollar Rate
                      <div className="w-100"></div>
                      <span style={{fontSize: '.65rem', marginLeft: '2rem', fontStyle: 'italic'}}>
                        IDR {this.props.dataDollarSetting.data? convertToRupiah(this.props.dataDollarSetting.data.value): '-'}
                      </span>
                    </Button>
                  </li>
                </>
              )}
            />

            {/* Setting */}
            <AuthorizedMenu
              isLoading={authData.isFetch}
              routes={authData.authRoutes}
              routeName="setting"
              render={()=>(
                <li className={this.activeRoute("/setting")}>
                  <a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}><i className="fa fa-cog"></i> Setting</a>
                  <ul className="nav-dropdown-items">
                    <li className="nav-item">
                      <NavLink to={'/setting/create'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Create Setting</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to={'/setting'} className="nav-link" activeClassName="active" isActive={this.handleIsActive}> Setting List</NavLink>
                    </li>
                  </ul>
                </li>
              )}
            />

          </ul>
        </nav>
      </div>
    )
  }
}

const mapStateToProps= ({ setting }) => ({
  dataDollarSetting: setting.dollarSetting
});

const mapDispatchToProps = (dispatch) => ({
  getDollarSettingData: () => dispatch(getDollarSettingData()),
  updateDollarSettingData: payloads => dispatch(updateDollarSettingData(payloads))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
