import React from "react";
import { Col, Row } from "reactstrap";
import { ModalSelectProduct, PaymentForm, PaymentSummary } from "./Components";
import { PaymentContext } from "./Context/PaymentContext";
import ModalTokenExpired from "./Components/ModalTokenExpired/ModalTokenExpired";
import { logout } from "../../../utils/authorization";
import privateView from "../../../components/hocs/privateView"
import { connect } from "react-redux";
import { processingFeePercentageOfflineTxSelector } from "../../../reducers/selectors/settingSelector";

function PaymentCreate(props) {
    const [product, setProduct] = React.useState([]);
    const [shippingFee, setShippingFee] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [voucher, setVoucher] = React.useState(null);
    const [isCashback, setIsCashback] = React.useState(false);
    const [subsidy, setSubsidy] = React.useState(0);
    const [walletAmount, setWalletAmount] = React.useState(0);
    const [credit, setCredit] = React.useState(0);
    const [point, setPoint] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [offerAmount, setOfferAmount] = React.useState(0);
    const [voucherApplied, setVoucherApplied] = React.useState(false);
    const [amountReceived, setAmountReceived] = React.useState(0);
    const [totalAdjusted, setTotalAdjusted] = React.useState(0);
    const [user, setUser] = React.useState(null);

    const [isOpen, setIsOpen] = React.useState(false);
    const [showExpiredModal, setShowExpiredModal] = React.useState(false);

    return (
        <PaymentContext.Provider
            value={{
                product,
                setProduct,
                shippingFee,
                setShippingFee,
                discount,
                setDiscount,
                voucherApplied,
                setVoucherApplied,
                voucher,
                setVoucher,
                isCashback,
                setIsCashback,
                subsidy,
                setSubsidy,
                walletAmount,
                setWalletAmount,
                credit,
                setCredit,
                point,
                setPoint,
                total,
                setTotal,
                offerAmount,
                setOfferAmount,
                amountReceived,
                setAmountReceived,
                totalAdjusted,
                setTotalAdjusted,
                user,
                setUser,
                processingFeeOfflineTx: props?.processingFeeOfflineTx
            }}
        >
            <Row className="animated fadeIn">
                <Col xs={12} md={6}>
                    <PaymentForm openModalProduct={() => setIsOpen(true)} pathname={props.location.pathname} history={props.history} />
                </Col>
                <Col xs={12} md={6}>
                    <PaymentSummary />
                </Col>
            </Row>
            <ModalSelectProduct
                isOpen={isOpen}
                onClose={() => { setIsOpen(false) }}
                showExpiredModal={() => setShowExpiredModal(true)}
            />
            <ModalTokenExpired isOpen={showExpiredModal} toggle={() => setShowExpiredModal(value => !value)} onClosed={() => logout(props.location.pathname, props.history)} />
        </PaymentContext.Provider>
    );
}

const mapStateToProps = (state)=> {
    const {auth: { isFetch, roles, isLogged, id, email } } = state;
    return {
        guardData: { isFetch, roles, isLogged, id, email },
        processingFeeOfflineTx: processingFeePercentageOfflineTxSelector(state),
    }
}

const enhance = connect(mapStateToProps);
export default enhance(privateView(PaymentCreate, ['cashier']));
