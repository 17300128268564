import React, { useContext, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import FontAwesomeIcon from "../../../../components/Icons/FontAwesomeIcon";
import { TextRupiah } from "../../../../components/Text";
import { _getFloatValue } from "../../../../utils/form";
import { PaymentContext } from "../Context/PaymentContext";
import { connect } from 'react-redux';
import { processingFeePercentageOfflineTxSelector } from '../../../../reducers/selectors/settingSelector';
import { getProcessingFeeSetting } from '../../../../actions/settingAction';

function PaymentSummary(props) {
    const {
        product,
        shippingFee,
        subsidy,
        voucher,
        voucherApplied,
        discount,
        credit,
        point,
        amountReceived,
        isCashback,
        setTotalAdjusted,
        user
    } = useContext(PaymentContext);

    const [preservedPoint, setPreservedPoint] = React.useState(0);
    const [lastPoint, setLastPoint] = React.useState(0);

    // Store the initial point value
    React.useEffect(() => {
        if (point > 0 && !preservedPoint) {
            setPreservedPoint(point);
        }
    }, [point]);

    // Store the last valid point value
    React.useEffect(() => {
        if (point > 0 && point !== lastPoint) {
            setLastPoint(point);
        }
    }, [point]);

    const offerAmounts = product.reduce((total, item) => {
        const itemPrice = _getFloatValue(item.asking_price);
        const quantity = item.selectedQuantity || 1;
        return total + (itemPrice * quantity);
    }, 0);

    const processingFeeAmount = offerAmounts * (props?.processingFeeOfflineTx / 100);
    const totalAmountBeforeDiscount = (((offerAmounts) - subsidy) + shippingFee + processingFeeAmount);
    const subtotal = isCashback ? totalAmountBeforeDiscount : totalAmountBeforeDiscount - discount;

    // Calculate point and credit usage
    let pointUsed = 0;
    let creditUsed = 0;

    // First handle points if available, limited by user's actual kick points balance
    if (point > 0 && user) {
        const availablePoints = parseInt(user.kick_point || 0);
        pointUsed = point;
        
        // Only limit points by available balance
        if (pointUsed > availablePoints) {
            pointUsed = availablePoints;
        }
    }

    // Then handle credit if available
    if (credit > 0 && user) {
        const remainingAmount = subtotal - pointUsed;
        const availableBalance = parseInt(user.balance || 0) + parseInt(user.seller_balance || 0);
        creditUsed = Math.min(credit, availableBalance, remainingAmount);
    }

    // Calculate total after applying points and credit
    const totalAdjustedAmount = Math.max(0, subtotal - pointUsed - creditUsed);

    React.useEffect(() => {
        setTotalAdjusted(totalAdjustedAmount);
    }, [totalAdjustedAmount]);

    React.useEffect(() => {
        props.onGetProcessingFeeSetting();
    }, []);

    return (
        <Card>
            <CardHeader>
                <FontAwesomeIcon iconType="align-justify" /> Purchase Summaries
            </CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th colSpan={2} className="text-center">
                                Purchase Summary
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {product.map((item, index) => {
                            const quantity = item.selectedQuantity || 1;
                            return Array(quantity).fill(null).map((_, i) => (
                                <tr key={`${index}-${i}`}>
                                    <td>
                                        {item?.product_variant?.display_name ?? ''}
                                    </td>
                                    <td>
                                        <TextRupiah 
                                            prefix="Rp. " 
                                            value={_getFloatValue(item.asking_price)} 
                                            align="right" 
                                            block 
                                        />
                                    </td>
                                </tr>
                            ));
                        })}
                        <tr>
                            <td>Processing Fee</td>
                            <td><TextRupiah prefix="Rp. " value={_getFloatValue(processingFeeAmount)} align="right" block /></td>
                        </tr>
                        <tr>
                            <td>{shippingFee === 0 ? 'WAREHOUSE PICKUP' : 'FLAT RATE'}</td>
                            <td><TextRupiah prefix="Rp. " value={_getFloatValue(shippingFee)} align="right" block /></td>
                        </tr>
                        {voucherApplied && <tr>
                            <td>Voucher ({voucher ? voucher.code : '-'})</td>
                            <td><TextRupiah prefix="Rp. " value={_getFloatValue(discount)} align="right" block /></td>
                        </tr>}
                        <tr>
                            <td>Subsidy Price</td>
                            <td className="v-center"><TextRupiah prefix="-Rp." value={_getFloatValue(subsidy)} align="right" block /></td>
                        </tr>
                        <tr>
                            <td><b>Sub Total</b></td>
                            <td>
                                <TextRupiah prefix="Rp. " value={_getFloatValue(subtotal)} align="right" block bold />
                            </td>
                        </tr>
                        <tr>
                            <td>Credit</td>
                            <td>
                                <TextRupiah prefix="-Rp. " value={_getFloatValue(creditUsed)} align="right" block />
                            </td>
                        </tr>
                        <tr>
                            <td>Kick Point</td>
                            <td>
                                <TextRupiah prefix="-Rp. " value={_getFloatValue(pointUsed)} align="right" block />
                            </td>
                        </tr>
                        <tr>
                            <td><b>Total</b></td>
                            <td>
                                <TextRupiah prefix="Rp. " value={_getFloatValue(totalAdjustedAmount)} align="right" block bold />
                            </td>
                        </tr>
                        {parseInt(amountReceived) > 0 ? <tr>
                            <td><b>Amount Received</b></td>
                            <td>
                                <TextRupiah prefix="Rp. " value={_getFloatValue(amountReceived)} align="right" block bold />
                            </td>
                        </tr> : <div />}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}

const mapStateToProps = (state) => ({
    processingFeeOfflineTx: processingFeePercentageOfflineTxSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    onGetProcessingFeeSetting: () => dispatch(getProcessingFeeSetting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSummary);
