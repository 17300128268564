import React, { useMemo, useState, useContext, useEffect } from "react";
import Style from "./ProductCard.module.css";
import { Button, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import { SimpleLineIcon } from "../../../../../../../components/Icons";
import { formatRupiah } from "../../../../../../../helpers/formatter";
import { getProductCondition, getProductStatus } from "../../../../Helper";
import { PaymentContext } from "../../../../Context/PaymentContext";
import { _getFloatValue } from "../../../../../../../utils/form";

const ProductCard = (props) => {
    const {
        data,
        onClickButton,
        isSelected,
        formMode,
        selectedStatus,
    } = props;

    const { product, setProduct } = useContext(PaymentContext);
    
    // Find existing product quantity if already selected
    const existingProduct = product.find(item => item.id === data.id);
    const [quantity, setQuantity] = useState(existingProduct?.selectedQuantity || 1);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    
    const categoryName = data?.product_variant?.product?.category?.name || "";
    const maxQuantity = data?.quantity || 0;
    const isShowingSizeText = ["sneakers", "apparels", "handbags"].includes(
        categoryName
    );

    const status = useMemo(() => {
        return getProductStatus(data);
    }, [data]);

    const condition = useMemo(() => {
        return getProductCondition(data);
    }, [data]);

    const isDisableAdd = selectedStatus !== "" && selectedStatus !== status;
    const isOutOfStock = maxQuantity === 0;

    // Update quantity when product changes in context
    useEffect(() => {
        if (existingProduct?.selectedQuantity) {
            setQuantity(existingProduct.selectedQuantity);
        }
    }, [existingProduct]);

    const handleDelete = () => {
        const updatedProducts = product.filter(item => item.id !== data.id);
        setProduct(updatedProducts);
    };

    const updateProductInContext = (newQuantity) => {
        const updatedProduct = product.map(item => {
            if (item.id === data.id) {
                return {
                    ...item,
                    selectedQuantity: newQuantity,
                    totalPrice: _getFloatValue(item.asking_price) * newQuantity
                };
            }
            return item;
        });
        setProduct(updatedProduct);
    };

    const handleQuantityChange = (value) => {
        const newValue = parseInt(value) || 0;
        if (newValue === 0) {
            setShowDeleteModal(true);
            return;
        }
        if (newValue > maxQuantity) {
            const finalQuantity = maxQuantity;
            setQuantity(finalQuantity);
            updateProductInContext(finalQuantity);
            return;
        }
        setQuantity(newValue);
        updateProductInContext(newValue);
    };

    const handleIncrement = () => {
        if (quantity < maxQuantity) {
            const newQuantity = quantity + 1;
            setQuantity(newQuantity);
            updateProductInContext(newQuantity);
        }
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
            updateProductInContext(newQuantity);
        } else {
            setShowDeleteModal(true);
        }
    };

    const handleDeleteConfirm = () => {
        setShowDeleteModal(false);
        handleDelete();
    };

    return (
        <>
            <div
                className={
                    formMode ? Style["product-card-form"] : Style["product-card"]
                }
            >
                <div
                    className={
                        formMode
                            ? Style["image-wrapper-form"]
                            : Style["image-wrapper"]
                    }
                >
                    <img
                        alt="product"
                        src={
                            data?.product_variant?.product_variant_images[0]
                                ?.signed_url ||
                            data?.product_variant?.product_variant_images[0]?.URL
                        }
                    />
                </div>
                <div className={Style["desc-wrapper"]}>
                    <div className={Style["cg-id"]}>
                        CG ID: {data?.consignment_id || "-"}
                    </div>
                    <div className={Style["product-name"]}>
                        {data?.product_variant?.display_name || "-"}
                    </div>
                    <div className={Style["seller"]}>
                        {data?.product_variant?.SKU || "-"}
                    </div>
                    <div className={Style["product-price"]}>
                        {data?.asking_price
                            ? formatRupiah(parseInt(data.asking_price))
                            : "-"}
                    </div>
                    <div className={Style["seller"]}>
                        Seller: {data?.user?.email}
                    </div>
                    <div className={Style["seller"]}>
                        Available Quantity: {maxQuantity}
                    </div>
                    <div className={Style["tags"]}>
                        <span id={`tag-1-${data.id}`} className={Style["tag-1"]}>
                            <span className={Style["tag-text"]}>{status}</span>
                        </span>
                        <span id={`tag-2-${data.id}`} className={Style["tag-2"]}>
                            <span className={Style["tag-text"]}>{condition}</span>
                        </span>
                        <span id={`tag-3-${data.id}`} className={Style["tag-3"]}>
                            <span className={Style["tag-text"]}>
                                {isShowingSizeText && "Size"}{" "}
                                {data?.size?.US || "-"}
                            </span>
                        </span>
                    </div>
                </div>
                <div className={Style["add-wrapper"]}>
                    {formMode ? (
                        <div className={Style["quantity-controls"]}>
                            <Button
                                outline
                                color="secondary"
                                onClick={handleDecrement}
                                className={Style["quantity-button"]}
                            >
                                -
                            </Button>
                            <Input
                                type="number"
                                value={quantity}
                                onChange={(e) => handleQuantityChange(e.target.value)}
                                min="0"
                                max={maxQuantity}
                                className={Style["quantity-input"]}
                            />
                            <Button
                                outline
                                color="secondary"
                                onClick={handleIncrement}
                                className={Style["quantity-button"]}
                            >
                                +
                            </Button>
                        </div>
                    ) : (
                        !isOutOfStock && (
                            <Button
                                outline
                                color={isSelected ? "secondary" : "primary"}
                                onClick={() => {
                                    if (isSelected) {
                                        setShowDeleteModal(true);
                                    } else {
                                        onClickButton({
                                            ...data,
                                            selectedQuantity: quantity,
                                            totalPrice: _getFloatValue(data.asking_price) * quantity
                                        });
                                    }
                                }}
                                className={Style["button"]}
                                disabled={isDisableAdd}
                                style={{ backgroundColor: isSelected ? "white" : "black", borderColor: isSelected ? "red" : "black" }}
                            >
                                {isSelected ? (
                                    <SimpleLineIcon iconType="trash" style={{ color: "red" }} />
                                ) : (
                                    "Add"
                                )}
                            </Button>
                        )
                    )}
                </div>
            </div>

            <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
                <ModalBody>
                    Are you sure you want to remove this item?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={handleDeleteConfirm}>
                        Remove
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ProductCard;
