import React from 'react';
import {
  Badge,
  Modal,
  ModalHeader,
  Card,
  CardTitle,
  CardSubtitle,
  CardText,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col
} from 'reactstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// import moment from 'moment';
import InputDatetime from '../../../components/Form/InputDatetime/InputDatetime';
// import 'moment/locale/id';

import { hasError, errorMessage, textChange, textValue, dateValue, dateChange } from '../../../utils/form';

export const courierOptions = [
  {
    label: 'JNT',
    value: 'JNT'
  },
  {
    label: 'TIKI',
    value: 'TIKI'
  },
  {
    label: 'JNE',
    value: 'JNE'
  },
  {
    label: 'GOSEND',
    value: 'GOSEND'
  },
  {
    label: 'PAXEL',
    value: 'PAXEL'
  },
  {
    label: 'POS',
    value: 'POS'
  },
  {
    label: 'Warehouse',
    value: 'WAREHOUSE'
  },
]

export const ShippingCard = props => {
  const { shipping } = props;
  const shippingStr = [
    shipping.street_address,
    shipping.country,
    shipping.province,
    shipping.city,
    shipping.district,
    shipping.sub_district,
    shipping.postal_code
  ].filter(item => item && item.length > 0).reduce((prev, now) => {
    if(prev.length){
      prev += ', ';
    }
    prev += now
    return prev
  }, "").trim()
  return (
    <Card body>
      <CardTitle>{props.displayName}</CardTitle>
      <CardSubtitle style={{fontSize:11,paddingBottom:5}}><i>({props.invoiceNumber})</i></CardSubtitle>
      <CardText>
        <b>{shipping.full_name}</b><br/>
        {shippingStr}<br/>
        {shipping.phone_number}
      </CardText>
      <CardText>
        <b>Shipping Notes from Buyer</b><br/>
        {shipping.note? shipping.note: '-'}<br/>
      </CardText>
    </Card>
  )

}

export class ModalDeliver extends React.Component{
  constructor(props){
    super(props);
    moment.tz.setDefault("Asia/Jakarta");
    this.state={
      id:null,
      ka_courier:'',
      ka_courier_option:'',
      ka_courier_price:0,
      awb_number:'',
      ka_sent:moment().format('YYYY-MM-DD HH:mm:00'),
      notes:'',
      isCourierEdit: false,
      error:null
    }
    this.handleChange=this.handleChange.bind(this);
    this.handleSelectCourierChange = this.handleSelectCourierChange.bind(this);
    this.onSubmit=this.onSubmit.bind(this);
  }

  handleChange(e){
    this.setState(textChange(e,{...this.state}));
  }

  handleSelectCourierChange(e){
    this.setState({...textChange(e,{...this.state}), ka_courier_custom: ""})
  }

  handleDatePickerChange(date, name){
    this.setState(dateChange(date,name, {...this.state}));
  }

  componentDidUpdate(prevProps){
    if(!prevProps.isOpen&&prevProps.isOpen!==this.props.isOpen&&this.props.sale!==null){
      const { ka_courier } = this.props.sale.sale_shipping;
      let selectedCourier = ka_courier
      const find = courierOptions.find(item => item.value === selectedCourier)
      selectedCourier = find? find.value : 'CUSTOM';
      const initalValue={
        ka_courier: selectedCourier,
        ka_courier_option:this.props.sale.sale_shipping.ka_courier_option,
        ka_courier_price:this.props.sale.sale_shipping.ka_courier_price,
        ka_courier_custom: selectedCourier === 'CUSTOM'? ka_courier: selectedCourier,
        awb_number:'',
        isCourierEdit: false,
        notes: this.props.sale.notes,
        ka_sent:moment().format('YYYY-MM-DD HH:mm:00'),
        error:null
      }
      this.setState({id:this.props.sale.id,...initalValue});
    }
    if(prevProps.isSubmit!==this.props.isSubmit&&this.props.isSubmit===false){
      this.setState({error:this.props.error});
    }
  }

  onSubmit(e){
    e.preventDefault();
    const {
      id,
      notes,
      ka_courier,
      ka_courier_custom,
      ka_courier_option,
      ka_courier_price,
      awb_number,
      ka_sent
    } = this.state;
    const payload = { notes, ka_courier: ka_courier === "CUSTOM"? ka_courier_custom: ka_courier, ka_courier_option, ka_courier_price, awb_number, ka_sent };
    this.props.onSubmit(id, payload);
  }

  render(){
    return(
      <Modal isOpen={this.props.isOpen} toggle={this.props.onCancel} className="modal-dialog modal-md">
        <ModalHeader toggle={this.props.onCancel}>Delivery Order</ModalHeader>
        <ModalBody>
          <Form>
          {
            this.props.sale &&
            <Row>
              <Col sm={12}>
                <ShippingCard
                  displayName={this.props.sale.sale_product.display_name}
                  invoiceNumber={this.props.sale.invoice_number}
                  shipping={this.props.sale.sale_shipping}
                />
              </Col>
            </Row>
          }
            <Row>
              <Col xs={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('ka_courier',this.state.error)})}>
                  <Label for="formDeliverCourier">
                    <div className="d-flex">
                      <div>Courier*</div>
                      {
                        this.state.ka_courier === "CUSTOM" ?
                        <div style={{marginLeft: "0.5rem"}}>
                          <Badge>CUSTOM</Badge>
                          { this.state.isCourierEdit? <span style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer", color: 'red'}} onClick={() => this.setState({ka_courier: "JNT"})}>select options...</span>:null }
                        </div>
                        :null
                      }
                      {
                        !this.state.isCourierEdit && (
                          <span
                            style={{marginLeft: "0.5rem", textDecoration: 'underline', cursor: "pointer"}}
                            onClick={() => this.setState({isCourierEdit: !this.state.isCourierEdit})}
                          >
                            Edit
                          </span>
                        )
                      }
                    </div>
                  </Label>
                  {
                    this.state.ka_courier !== "CUSTOM"?
                    <Input
                      type="select"
                      id="formDeliverCourier"
                      name="ka_courier"
                      readOnly={!this.state.isCourierEdit}
                      placeholder="Courier example: JNE*"
                      value={textValue('ka_courier',this.state)}
                      onChange={this.handleSelectCourierChange}
                    >
                      {
                        courierOptions.map(({value, label}, index)=> <option value={value} key={index}>{label}</option>)
                      }
                    </Input>
                    :
                    <Input
                      type="text"
                      id="formDeliverCourier"
                      name="ka_courier_custom"
                      readOnly={!this.state.isCourierEdit}
                      placeholder="Courier example: JNE*"
                      value={textValue('ka_courier_custom',this.state)}
                      onChange={this.handleChange}
                    />
                  }
                  {errorMessage('ka_courier',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('ka_courier_option',this.state.error)})}>
                  <Label for="formDeliverCourierOption">Courier Option</Label>
                  <Input
                    type="text"
                    id="formDeliverCourierOption"
                    name="ka_courier_option"
                    placeholder="Courier Option example: YES"
                    value={textValue('ka_courier_option',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('ka_courier_option',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('ka_courier_price',this.state.error)})}>
                  <Label for="formDeliverCourierPrice">Courier Price*</Label>
                  <Input
                    type="text"
                    id="formDeliverCourierPrice"
                    name="ka_courier_price"
                    placeholder="Courier Price*"
                    value={textValue('ka_courier_price',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('ka_courier_price',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('ka_sent',this.state.error)})}>
                  <Label for="formDeliverSentDate">Sent Date*</Label>
                  <InputDatetime
                    name="ka_sent"
                    id="formDeliverSentDate"
                    timeFormat="HH:mm"
                    value={dateValue('ka_sent',this.state)}
                    onChange={(date)=>this.handleDatePickerChange(date,'ka_sent')}
                    inputProps={{placeholder:'When product to sent?*'}}
                  />
                  {errorMessage('ka_sent',this.state.error)}
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('awb_number',this.state.error)})}>
                  <Label for="formDeliverAwbNumber">AWB Number*</Label>
                  <Input
                    type="text"
                    id="formDeliverAwbNumber"
                    name="awb_number"
                    placeholder="AWB Number/Nomor Resi*"
                    value={textValue('awb_number',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('awb_number',this.state.error)}
                </FormGroup>
              </Col>
              <div className="w-100"></div>
              <Col xs={12}>
                <FormGroup  className={classnames({'has-danger has-feedback':hasError('ka_courier',this.state.error)})}>
                  <Label for="formDeliverNotes">
                    Notes
                  </Label>
                  <Input
                    type="textarea"
                    id="formDeliverNotes"
                    name="notes"
                    placeholder="Notes..."
                    value={textValue('notes',this.state)}
                    onChange={this.handleChange}
                  />
                  {errorMessage('notes',this.state.error)}
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <span className="text-sm">Fields marked with * are required</span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={this.props.isSubmit} onClick={this.onSubmit}>{this.props.isSubmit?'Submitting...':'Deliver Now'}</Button>{' '}
          <Button color="secondary" onClick={this.props.onCancel}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
ModalDeliver.propTypes={
  sale:PropTypes.object,
  isSubmit:PropTypes.bool,
  error:PropTypes.object,
  isOpen:PropTypes.bool,
  onSubmit:PropTypes.func,
  onCancel:PropTypes.func
}